import Header from "../Components/Header.js";
import Navbar from "../Components/Navbar.js";

export default function HtmlCourseHeader(){
  return(
    <>
      <Navbar />
      <Header />
    </>
  )
}