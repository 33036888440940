// import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import MainLayout from './MainLayout';
import HtmlCourseLayout from './HtmlCourseLayout';
import './App.css';

// import pages
import Home from "./pages/Home";
import About from "./pages/About";
import Course from "./pages/Course";
import Interview from "./pages/Interview";
import Interview_detail from "./pages/Interview_detail";
import Contact from './pages/Contact';
import Login from './pages/Login';
import Signup from './pages/Signup';

// course components
import Html_course from './Courses/Html_course';

const App = () => (
  <Router>
    <Routes>
      <Route element={<MainLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/course" element={<Course />} />
        <Route path="/interview" element={<Interview />} />
        <Route path="/interview_detail" element={<Interview_detail />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
      <Route element={<HtmlCourseLayout />}>
        <Route path="/html_course" element={<Html_course />} />
      </Route>
    </Routes>
  </Router>
);

export default App;
